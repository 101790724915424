<template>
  <app-modal
    :title="$t('article.modal.poll_update_votes')"
    @close="close"
    modalSize="modal-md eagle-poll-votes-modal"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row">
        <input type="hidden" v-model="poll.id">
        <div class="col-lg-12" v-for="(option, index) in poll.options" :key="`item-${index}`">
          <app-input
            v-model="option.votes"
            :label="option.title"
            :required="true"
            type="number"
            :min="0"
          >
          </app-input>
        </div>
      </div>
    </template>

    <!-- Footer -->
    <template slot="footer">
      <div class="row text-center">
        <div class="col-md-12">
          <div class="input-group-btn">
            <button @click="save" type="button" class="btn btn-green">{{ $t('buttons.save') }}</button>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Input from '../form/inputs/Input'
import Modal from '../shared/Modal'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'PollVotesModal',
  props: {
    poll: {
      type: Object
    }
  },
  data () {
    return {}
  },
  computed: {},
  components: {
    appModal: Modal,
    appInput: Input
  },
  methods: {
    close () {
      this.$emit('close')
    },
    preparePollRequest (poll) {
      const data = {
        id: poll.id,
        options: []
      }
      data.options = poll.options.map(function (option) {
        return { id: parseInt(option.id), votes: parseInt(option.votes) }
      })
      return data
    },
    async save () {
      console.log()
      this.$store.dispatch('poll/updateVotes', this.preparePollRequest(this.poll))
        .then(() => {
          if (this.$store.getters['poll/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
            this.$emit('close')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['poll/error'])
          }
        })
        .catch(error => console.log(error))
    }
  }
}
</script>

<style lang="scss">
.eagle-poll-votes-modal {
  .modal-footer {
    justify-content: center;
  }
}
</style>
