<template>
  <section>
    <div
      v-for="(option, index) in poll.options"
      :key="index"
      class="options-results"
    >
      <div class="options-results__info">
        <span class="options-results__info__id">
          {{ `ID: ` }}
          {{ option.id }}
        </span>
        <span class="options-results__info__title">
          {{ `|` }}
          {{ option.title }}
        </span>
      </div>
      <div
        class="options-results__votes"
        :style="getPercentageVoteStyle(option)"
      >
        <span class="options-results__votes__label">
          {{ option.votes }}
        </span>
      </div>
    </div>
    <div class="options-results__footer">
      <span class="options-results__footer__label">
        {{ $t('poll.total_votes') }}
      </span>
      <span class="options-results__footer__value">
        {{ poll.totalVotes }}
      </span>
    </div>
  </section>
</template>

<script>

export default {
  name: 'PollResults',
  props: {
    poll: {
      type: Object,
      required: true
    }
  },
  methods: {
    getPercentageVoteStyle (option) {
      const percentage = Math.min(option.votes, 100)
      return {
        '--vote-width': `${percentage}%`,
        '--vote-left-position': `${100 - percentage}%`,
        '--vote-number-left-position': `calc(${100 - percentage}% - 30px)`
      }
    }
  }
}
</script>

<style scoped lang="scss">
.options-results {
  background-color: #FFFFFF;
  margin-bottom: rem(10px);
  padding: rem(10px) 0rem rem(10px) rem(16px);
  display: flex;
  gap: 2%;
  border: 1px solid #D1DBE4;
  border-radius: rem(6px);
  &__info {
    flex: 0 0 49%;
    color: #465674;
    &__id {
      @include font(400 14px "Roboto");
    }
    &__title {
      @include font(700 14px "Roboto");
    }
  }
  &__votes {
    flex: 0 0 49%;
    height: 20px;
    border-bottom-left-radius: rem(6px);
    border-top-left-radius:  rem(6px);
    position: relative;
    background-color: #F2F3F7;
    &__label {
      position: absolute;
      left: var(--vote-number-left-position);
      z-index: 1;
    }
  }

  &__votes:after {
    content: '\A';
    position: absolute;
    background-color: #8FA7D8;
    border-bottom-left-radius: rem(10px);
    border-top-left-radius:  rem(10px);
    top: 0;
    bottom: 0;
    left: var(--vote-left-position);
    width: var(--vote-width);
  }
  &__footer {
    text-align: right;
    color: #8A96AC;
    &__label {
      @include font(400 14px "Roboto");
    }
    &__value {
      @include font(700 14px "Roboto");
    }
  }
}
</style>
